import React, { useEffect, useState } from 'react';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Button, Container, FileButton, Group, Loader, Select, TextInput, Tooltip } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { modals } from '@mantine/modals';
import { AddResourceForm } from './AddResourceModal';
import useComputedSlug from '../../hooks/useComputedSlug';
import { IconFilePlus } from '@tabler/icons-react';
import StringUtils from '@dr-pam/common-components/Utils/StringUtils';
import { ResourceListFragment, ResourceSingleFragment, ResourceTypeListFragment } from '../../graphql/graphql';
import { useResourceService } from '../../services/ResourceService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { useFirebaseService } from '@dr-pam/common-components/Components/Firebase/FirebaseProvider';
import z from 'zod';

export const validationSchema = z.object({
	title: z.string().nonempty().max(512),
	slug: z.string().nonempty().max(512),
	filename: z.string().nonempty().max(512),
});

export type EditResourceModalProps = {
	modalId: string;
	current: ResourceListFragment;
	onCancel: () => void;
	onEdited: (resource: ResourceSingleFragment) => void;
};

export default function EditResourceModal(props: EditResourceModalProps) {
	const { modalId, current, onCancel, onEdited } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const [fileToUpload, setFileToUpload] = useState<File | null>(null);
	const [resourceTypes, setResourceTypes] = useState<ResourceTypeListFragment[]>([]);

	const firebaseService = useFirebaseService();
	const resourceService = useResourceService();

	useEffect(() => {
		const loader = addLoader();
		const request = resourceService.getAllTypes();
		request.response
			.then((types) => {
				setResourceTypes(types);
			})
			.catch((err) => {
				NotificationUtils.showError(err as Error, 'Failed to load resource types');
			})
			.finally(() => {
				removeLoader(loader);
			});
	}, [addLoader, removeLoader, resourceService]);

	const form = useForm<EditResourceForm>({
		initialValues: {
			title: current.title,
			slug: current.slug,
			url: current.url,
			filename: current.filename.replace(/^resources\/[^/]+\/[^/]+\//, ''),
			resourceTypeId: current.resourceTypeId,
		},
		validate: zodResolver(validationSchema),
	});

	const handleFileSelected = (file: File | null) => {
		if (file) {
			form.setValues({
				title: form.values.title || file.name,
				slug: form.values.slug || StringUtils.slugifyForUrl(file.name.replace(/\.[^/.]+$/, '')),
				url: StringUtils.slugifyForFilesystem(file.name),
				filename: StringUtils.slugifyForFilesystem(file.name),
			});
		} else {
			form.setValues({
				url: '',
				filename: '',
			});
		}
		setFileToUpload(file);
	};

	const handleSubmit = async (values: EditResourceForm) => {
		const loader = addLoader();

		try {
			let filename: string | null = null;
			let url: string | null = null;

			if (fileToUpload) {
				filename = `resources/${current.id}/${Date.now()}/${fileToUpload.name}`;

				const uploadRequest = firebaseService.uploadFile(filename, fileToUpload);
				await uploadRequest.response;
				url = firebaseService.getRedirectUrl(filename);
			}

			const updatedResource = await resourceService.update(current.id, {
				title: { set: values.title },
				slug: { set: values.slug },
				url: { set: url ?? values.url },
				filename: { set: filename ?? values.filename },
				resourceType: values.resourceTypeId
					? {
							connect: {
								id: values.resourceTypeId,
							},
					  }
					: undefined,
			});
			onEdited(updatedResource);
			NotificationUtils.showSuccess('Successfully updated resource', updatedResource.title);
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to update resource');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	const slugComputedFromProps = useComputedSlug(form, 'slug', 'title');

	const resourceTypeData = resourceTypes.map((resourceType) => ({
		value: resourceType.id,
		label: resourceType.name,
	}));

	return (
		<Container>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Group align="flex-end" justify="flex-end">
					<TextInput
						label="Upload file"
						{...form.getInputProps('filename')}
						withAsterisk
						disabled
						style={{ flexGrow: 1 }}
					/>
					<FileButton onChange={handleFileSelected}>
						{(props) => (
							<Tooltip label="Click to upload file">
								<Button {...props}>
									<IconFilePlus />
								</Button>
							</Tooltip>
						)}
					</FileButton>
				</Group>
				<TextInput
					{...slugComputedFromProps}
					label="Title"
					description="The title of the resource. Forms the basis of the slug."
					disabled={isLoading}
					withAsterisk
				/>
				<TextInput
					{...form.getInputProps('slug')}
					label="Slug"
					description="This will appear in the browser URL."
					disabled={isLoading}
					withAsterisk
				/>
				<Select
					{...form.getInputProps('resourceTypeId')}
					data={resourceTypeData}
					label="Resource type"
					placeholder={isLoading ? 'Loading...' : '--- Please select ---'}
					disabled={isLoading}
				/>
				<Group justify="flex-end" mt="md">
					<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
						Cancel
					</Button>
					<Button type="submit" disabled={isLoading}>
						Save resource {isLoading && <Loader size="xs" ml="xs" />}
					</Button>
				</Group>
			</form>
		</Container>
	);
}

export type EditResourceForm = Omit<AddResourceForm, 'categoryId'>;
